<template>
  <div>
    <static-fullscreen-card @sendHeight="cardHeight = $event" :title="false" v-if="m">
      <template v-slot:header> </template>
      <v-data-table
        :headers="headers"
        :items="items"
        class="elevation-1"
        :item-class="itemrowbackground"
        :height="cardHeight - 32"
        hide-default-footer
        fixed-header
        disable-sort
        disable-pagination
        disable-filtering
        @click:row="onClickRow"
      >
        <template v-slot:item.actions="{ item }">
          <v-btn
            v-if="item.phone > 1"
            class="ma-1"
            dark
            :small="true"
            :color="phoneStatus.phone == parseInt(item.phone) ? 'yellow' : 'error'"
            @click.stop="onItemCall(item)"
          >
            Вызов
          </v-btn>
          <v-btn
            v-if="false"
            class="ma-1"
            fab
            dark
            :x-small="$vuetify.breakpoint.mdAndDown || true"
            :small="!$vuetify.breakpoint.mdAndDown && false"
            color="primary"
            :href="item.vendor == 'avito' ? 'https://avito.ru/' + item.url : item.url"
            target="_blank"
          >
            <v-icon dark> mdi-open-in-new </v-icon>
          </v-btn>
        </template>

        <template v-slot:item.icon="{ item }" class="d-flex">
          <img v-if="item.vendor == 'cian'" height="25px" src="/img/icon-cian.png" />
          <img v-if="item.vendor == 'avito'" height="25px" src="/img/icon-avito.png" />
          <img v-if="item.is_up" src="/img/icon-up.jpg" width="20px" />
        </template>
        <template v-if="$root.profile.role == 1000" v-slot:item.offer_time="{ item }">
          <span v-if="$vuetify.breakpoint.xsOnly" class="px-2">
            <img v-if="item.vendor == 'cian'" height="25px" src="/img/icon-cian.png" />
            <img v-if="item.vendor == 'avito'" height="25px" src="/img/icon-avito.png" />
            <img v-if="item.is_up" src="/img/icon-up.jpg" width="20px" />
          </span>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">{{ item.offer_time }}</span>
            </template>
            <span>{{ new Date(item.parsedon).datetime }}</span>
            <br />
            <span>{{ item.parser_type }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </static-fullscreen-card>

    <portal to="v-main">
      <ViewItem ref="offer" v-if="true" v-model="showItem" :autoCall="autoCall" :offer="offerShow" @close="itemShowClose($event)" @work="openObject($event)" />
      <ViewObject v-if="showObjectDialog" v-model="showObjectDialog" :idShow="idObject" @close="showObjectDialog = false" />
    </portal>
  </div>
</template>

<script>
import parser from "./parser";
import { getAccess, genModel } from "@/components/mixings";
export default {
  mixins: [genModel],
  components: {
    ViewItem: () => import("./views/offerView"),
    ViewObject: () => import("./views/objectView"),
  },
  data() {
    return {
      idObject: 0,
      cardHeight: 0,
      autoCall: false,
      offerShow: null,
      showItem: false,
      showObjectDialog: false,
      idItemShow: null,
      filters: { category_id: 1 },
      m: this.$store.getters["config/get"].models?.offer || null,

      defaults: {
        sort: { key: "id", order: "DESC" },
      },
      items: [],
    };
  },
  created() {
    this.$root.title = "Аренда";
    this.$root.$on("websocket", this.addOffer);
    this.fetchData();
    console.log("created");
  },
  beforeDestroy() {
    this.$root.$off("websocket", this.addOffer);
    console.log("destroyed");
  },

  computed: {
    url() {
      return this.m.url;
    },
    phoneStatus: {
      get() {
        return this.$store.getters["config/getPhoneStatus"];
      },
    },

    model() {
      let res = this.getModelList(this.m);
      if (this.$vuetify.breakpoint.xsOnly) res.find((r) => r.name == "icon").hidden = true;
      if (this.$root.profile.role === 1000) res.find((r) => r.name == "parser_type").hidden = false;
      return res;
    },
    headers() {
      let res = this.model
        .filter((m) => !m.hidden)
        .map((m) => {
          return { text: m.title, value: m.name, class: m.class, width: m.width };
        });
      return res;
    },
  },
  methods: {
    onClickRow(item, e, w) {
      const clickedColumn = e.target;
      //console.log({ item, clickedColumn, e, w });
      this.onItemShow(item);
    },
    openObject(id) {
      if (!id) return;
      this.idObject = id;
      this.showObjectDialog = true;
    },
    onItemCall(item) {
      window.open("tel:" + item.phone, "_self");
      return;
      this.idItemShow = item.id;
      this.offerShow = item;
      this.autoCall = true;
      this.showItem = true;
      // this.$refs.offer.onCall(item);
      return;
      if (this.phoneStatus.status !== true) return;
      if (isNaN(item.phone)) return;
      if (item.phone) this.$root.$emit("global-call", item.phone);
    },

    onItemShow(item) {
      this.idItemShow = item.id;
      this.offerShow = item;
      this.autoCall = false;
      this.showItem = true;
    },
    itemShowClose(d) {
      this.showItem = !true;
      if (this.idItemShow) {
        let item = this.items.findIndex((i) => i.id == d.id);
        console.log("find", item);
        if (item > -1) this.items[item] = Object.assign({}, d);
        // this.$refs.table.updateRowData(this.idItemShow);
      }

      this.loading = true;
      this.loading = !true;
      this.offerShow = null;
    },
    itemrowbackground(item, lowestentry) {
      return item?.isNew ? "teal lighten-4" : "";
    },
    async fetchData() {
      this.loading = true;
      let where = this.where || this.defaults?.where || {};
      let filters = Object.assign({}, this.filters, where);
      //let filters = Object.assign({}, this.filters);
      let params = {
        sort: JSON.stringify({ key: "parsedon", order: "desc" }),
        filters,
        pager: { limit: 50 },
      };

      let response = await this.$axios.get(this.url, { params });
      let data;
      if ((data = response.data.data)) {
        this.items = data;
      }
      this.loading = false;
    },
    addOffer(data) {
      if (data.e && data.e == "offer") {
        //console.log("addOffer ok", data.d.vendor_id, data.d);
        if (data.t == "new" && typeof data.d === "object") {
          let offer = data.d;
          offer["isNew"] = 1;
          if (offer.category_id == 1) this.items.unshift(offer);
          // if (this.autoCall) this.onItemCall(offer);
        }
      }
    },
  },
};
</script>

<style lang="scss">
.v-data-table__mobile-row {
  min-height: 36px !important;
}
.col-min-width-50 {
  min-width: 72px !important;
}
</style>
